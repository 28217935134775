<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
      
          <banner title="STAFF PROFILE" :breadcrumb="[
           {
              label: 'Dashboard',
            },
            {
              label: 'Staff',
            },
            { label: 'Profile' },
          ]">
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-sm-5" style="padding:5px 5px 5px 5px">
            <div
              class="card custom-card "
              style="
              
                height: 100%;
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Staff Profile</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Staff Profile</b>
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img style="width: 40%; border-radius: 15px" :src="photo" alt="" />
                    <br />
                    <span style="color: #00364f">
                      <button
                      v-if="staff.loginStatus == 'true'"
                        type="button"
                        class="btn text-white btn-sm radius mt-1"
                        style="
                          background-color: green;
                          padding: 5px 15px 5px 15px;
                          box-shadow: 0px 0px 10px gray;
                        "
                      >
                        <span >Active</span>
                      </button>
                      <button
                      v-else
                        type="button"
                        class="btn text-white btn-sm radius mt-1"
                        style="
                          background-color: #f21000;
                          padding: 5px 15px 5px 15px;
                          box-shadow: 0px 0px 10px gray;
                        "
                      >
                        <span v-if="staff.loginStatus == 'true'">Active</span>
                      </button>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="staff">{{ staff.code }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="staff">{{ staff.name }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <span v-if="staff">{{ dateTime(staff.dob) }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Gender : </b>
                      <span v-if="staff">{{ staff.gender }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone : </b>
                      <span v-if="staff">{{ staff.phone }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>E-mail : </b>
                      <span v-if="staff">{{ staff.email }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Address : </b>
                      <span v-if="staff">{{ staff.address }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>State: </b>
                      <span v-if="staff.state">{{ staff.state.name }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Aadhar Number: </b>
                      <span v-if="staff.staff_kyc">{{ staff.staff_kyc.aadharNo }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Pan: </b>
                      <span v-if="staff.staff_kyc">{{ staff.staff_kyc.panNo }}</span>
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 15px; margin-top: -20px"
                      :src="photo"
                      alt=""
                    />
                    <p
                      v-if="staff.loginStatus == 'true'"
                      class="text-white btn btn-sm"
                      style="
                        background-color: green;
                        width: 70px;
                        box-shadow: 2px 2px 5px gray;
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b> Active</b>
                    </p>
                    <p
                      v-else
                      class="text-white btn btn-sm"
                      style="
                        background-color: red;
                        width: 70px;
                        box-shadow: 2px 2px 5px gray;
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b> InActive</b>
                    </p>
                  </div>
                </div>

                <!-- bussiness Detais  -->
                <div class="row">
                  <p></p>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">More About Staff </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Staff designation: </b>
                      <span v-if="staff.designation">{{ staff.designation.name }}</span>
                    </div>
                  </div>
                </div>
                <!-- end  bussiness Detais  -->

                <div class="row mt-1">
                  <div class="col-12 text-end">
                    <button
                      type="button"
                      class="btn text-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#doccuments"
                      style="background-color: #f21300; margin-right: 5px"
                    >
                      <b
                        >Kyc Doccuments &nbsp;
                        <i class="fa fa-hand-pointer-o"></i>
                      </b>
                    </button>
<!-- 
                    <router-link
                      to="/super-admin/office-staff"
                      type="button"
                      class="btn btns text-white btn-sm"
                    >
                      <b
                        >Edit Staff &nbsp;
                        <font-awesome-icon icon="edit" />
                      </b>
                    </router-link> -->
                  </div>
                </div>
                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address :</b>
                      <span v-if="staff">{{ staff.ipAddress }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login :</b>
                      <span v-if="staff">{{ dateTimeWithTime(staff.last_login)  }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-7 col-md-7 col-sm-7" style="display: none;padding:5px 5px 5px 5px" >
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card radius"
              style="box-shadow: 0px 0px 10px gray; margin-left: 3px; height: 100%"
            >
              <div class="card-body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal kyc doccuments show  -->
  <div
    class="modal fade"
    id="doccuments"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm text">
              <tr class="table-wrapper-scroll-y tr-head rounded-circle">
                <th>+</th>
                <th>Name</th>
                <th class="text-end">Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; margin-right: 5px"
                      :src="adharFrontImg"
                      alt=""
                    />
                  </td>

                  <td>Aadhar Front</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <!-- <a :href="adharFrontImg" style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;"
                        class="btn btns text-white btn-sm" download>
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a> -->

                      <button
                        type="button"
                        @click="downloadPdf(aadharFrontPdf)"
                        class="btn btns btn-sm text-white"
                        style="background-color: #00364f; padding: 5px 4.5px 5px 4.5px"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; margin-right: 5px"
                      :src="adharBackImg"
                      alt=""
                    />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <!-- <a :href="adharBackImg" style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;"
                        class="btn btns text-white btn-sm" download="true">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a> -->

                      <button
                        type="button"
                        @click="downloadPdf(aadharBackPdf)"
                        class="btn btns btn-sm text-white"
                        style="background-color: #00364f; padding: 5px 4.5px 5px 4.5px"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; margin-right: 5px"
                      :src="panImg"
                      alt=""
                    />
                  </td>

                  <td>Pan Card</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <!-- <a :href="panImg" style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;"
                        class="btn btns text-white btn-sm" download>
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a> -->
                      <button
                        type="button"
                        @click="downloadPdf(panPdf)"
                        class="btn btns btn-sm text-white"
                        style="background-color: #00364f; padding: 5px 4.5px 5px 4.5px"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- start model  -->
  <div
    class="modal fade"
    id="createbeneficiary"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="border-radius: 50px"
  >
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 1.4vw">
        <div class="modal-header bg-white" style="border-radius: 1.4vw">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b id="modal-heading-update">New</b> <b> Beneficiary Account</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- (edit == 'false') ? () : updatebeneficiaryAcount() -->
          <form @submit.prevent="createbeneficiaryAcount">
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    class="form-control"
                    v-model="beneficiary_Form.name"
                    placeholder="Account Holder"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    class="form-control"
                    v-model="beneficiary_Form.name"
                    placeholder="Account Holder"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="beneficiary_Form.account_number"
                    class="form-control"
                    placeholder="Account Number"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="beneficiary_Form.account_number"
                    class="form-control"
                    placeholder="Account Number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="beneficiary_Form.ifsc"
                    class="form-control"
                    placeholder="IFSC "
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="beneficiary_Form.ifsc"
                    class="form-control"
                    placeholder="IFSC "
                  />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="beneficiary_Form.bank_name"
                    class="form-control"
                    placeholder="Bank Name"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="beneficiary_Form.bank_name"
                    class="form-control"
                    placeholder="Bank Name"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="beneficiary_Form.phone"
                    class="form-control"
                    placeholder=" Mobile Number"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="beneficiary_Form.phone"
                    class="form-control"
                    placeholder=" Mobile Number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group">
                  <select
                    class="form-select"
                    v-model="beneficiary_Form.status"
                    aria-label="Default select example"
                    placeholder="Status"
                  >
                    <option value="true">Active</option>
                    <option value="false">InActive</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white"
                data-bs-dismiss="modal"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
</template>

//
<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import moment from "moment";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "SuperAdminStaffProfile",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      wallet: {},
      panPdf: "",
      aadharFrontPdf: "",
      aadharBackPdf: "",
      passBookPdf: "",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      extensionkycPassbookPhoto: "",
      beneficiary_Form: {
        staff_id: this.$route.params.id,
        beneficiary_id: null,
        account_number: null,
        name: "",
        ifsc: null,
        bank_name: "",
        phone: "",
        status: "",
        remark: "",
      },
      photo: this.$store.state.placeholderImg,
      staff: {},
      state: {},
      staffkyc: [],
      msg: "",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      passBookImgUrl: "",
      beneficiaryaccount: [],
    };
  },
  methods: {
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm a");
      }
      
    },
    downloadPdf(fileName) {
      window.open(fileName);
    },
    loadStates() {
      this.$axios
        .get(`superadmin/state/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.state = res.data.data;
        });
    },

    loadstaff() {
      this.loading = true;
      this.$axios
        .get(`superadmin/staff/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("______________staff______________________");
          this.staff = res.data.data;
          if (this.staff.staff_wallet) {
            this.wallet = this.staff.staff_wallet;
          }
          console.log(this.staff);

          if (this.staff.photo != null) {
            this.photo =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/thumbs/" +
              this.staff.photo;
          } else {
            this.photo = this.$store.state.placeholderImg;
          }

          if (this.staff.staff_kyc.aadharBackPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/thumbs/" +
              this.staff.staff_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/staff/" +
                this.staff.id +
                "/thumbs/" +
                this.staff.staff_kyc.aadharBackPhoto;
            }
            // this.adharBackImg = this.$store.state.imgUrl + '/staff/' + this.staff.id + '/thumbs/' + this.staff.staff_kyc.aadharBackPhoto
            if (
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/" +
              this.staff.staff_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/staff/" +
                this.staff.id +
                "/" +
                this.staff.staff_kyc.aadharBackPhoto;
            }

            this.aadharBackPdf = this.adharBackImg;
            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }

          if (this.staff.staff_kyc.aadharFrontPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/thumbs/" +
              this.staff.staff_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/staff/" +
                this.staff.id +
                "/thumbs/" +
                this.staff.staff_kyc.aadharFrontPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/" +
              this.staff.staff_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/staff/" +
                this.staff.id +
                "/" +
                this.staff.staff_kyc.aadharFrontPhoto;
            }

            this.aadharFrontPdf = this.adharFrontImg;
            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }

          if (this.staff.staff_kyc.panPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/thumbs/" +
              this.staff.staff_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/staff/" +
                this.staff.id +
                "/thumbs/" +
                this.staff.staff_kyc.panPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/staff/" +
              this.staff.id +
              "/" +
              this.staff.staff_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/staff/" +
                this.staff.id +
                "/" +
                this.staff.staff_kyc.panPhoto;
            }

            this.panPdf = this.panImg;

            this.extensionkycPanPhoto = this.panImg.split(".").pop();

            if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.staffView
    console.log(this.$store.state.superAdminPageTitles.staffView)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadstaff();
            
      this.loadPageTitle()
      // this.loadStates();
      // this.loadWallet();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #e70c0c;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
